<template>
	<div id="image-uploader" class="uploader row row-cols-2">
		<draggable :list="this.images"
				   @start="this.dragStart"
				   @end="this.dragEnd"
				   :disabled="!this.is_draggable"
				   tag="transition-group"
				   item-key="id">
			<template #item="{element, num}">
				<div class="col"
					 :class="{'dropzone': this.isDragged}"
					 :data-id="element.id">
					<div class="media" :class="{ 'active': element.is_main }">
						<div class="media-bg" :style="'background-image: url(\'' + element.thumbnail + '\')'" :title="'Фото ' + num" />
						<div class="media-actions mt-1">
							<a v-show="this.images.length > 1"
							   href="javascript:{}"
							   class="btn btn-light rounded-3 mx-2 my-1"
							   @mousedown="this.enableDrag">
								<img :src="require('@/assets/icons/move-icon.svg')" alt="Переместить" class="d-inline-flex m-auto" width="16" height="16" style="margin-top: 1px;">
							</a>
							<a v-show="element.is_temp && element.is_editable" href="javascript:{}"
							   class="btn btn-light rounded-3 mx-2 my-1"
							   data-bs-toggle="modal"
							   data-bs-target="#editImageModal"
							   :data-bs-image-id="element.id"
							   :data-bs-image-src="element.fullsize"
							   :data-bs-file-name="element.filename">
								<img :src="require('@/assets/icons/setting.svg')" alt="Редактировать" class="d-inline-flex m-auto" width="16" height="16" style="margin-top: 1px;">
							</a>
							<a href="javascript:{}"
							   class="btn btn-light rounded-3 mx-2 mb-auto mt-1"
							   @click="this.deleteImage(element.id)">
								<img :src="require('@/assets/icons/delete.svg')" alt="Удалить" class="d-inline-flex m-auto" width="16" height="16" style="margin-top: 1px;">
							</a>
						</div>
					</div>
				</div>
			</template>
		</draggable>
		<div v-if="this.images.length < this.max" class="col">
			<div class="media add-media"
				 :class="{'loading': (this.uploadReadyState === false)}">
				<div class="media-bg" @click="this.showUpload">
					<img v-show="this.uploadReadyState" :src="require('@/assets/icons/add-icon.svg')"
						 alt="Добавить" class="add-icon mx-auto"
						 width="16"
						 height="16" />
					<div v-show="this.uploadReadyState === false"
						 class="progress position-absolute mx-2 start-0 end-0 top-50 translate-middle-y">
						<div class="progress-bar bg-success progress-bar-striped progress-bar-animated"
							 role="progressbar"
							 :style="'width: ' + this.uploadProgress + 'px'"
							 :aria-valuenow="this.uploadProgress"
							 aria-valuemin="0"
							 aria-valuemax="100" />
					</div>
				</div>
				<input type="file"
					   ref="addImageInput"
					   style="display: none !important;"
					   :id="this.id"
					   :name="this.name"
					   :accept="this.accept"
					   :required="this.required"
					   :multiple="this.multiple" />
			</div>
		</div>
		<div v-else class="col-12">
			<div class="form-text d-flex fs-5 mb-3 text-danger">
				<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
				К сожалению, Вы не можете прикрепить более {{ this.declOfNum(this.max, ['изображения', 'изображений', 'изображений'], true) }}.
			</div>
		</div>
		<div v-if="this.valid && typeof this.valid != 'boolean'" class="col-12">
			<div class="form-text d-flex fs-5 mb-3">
				<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
				<span class="text-success" v-html="this.valid" />
			</div>
		</div>
		<div v-else-if="this.invalid && typeof this.invalid != 'boolean'" class="col-12">
			<div class="form-text d-flex fs-5 mb-3">
				<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
				<span class="text-danger" v-html="this.invalid" />
			</div>
		</div>

	</div>

	<EditImageModal @applyFlip="(data) => this.applyFlip(data)"
					@applyRotate="(data) => this.applyRotate(data)"
					@applyCrop="(data) => this.applyCrop(data)"
					@applyChanges="(data) => this.imageEdited(data)" />

</template>

<script>
import api from "@/api";
import heic2any from 'heic2any';
import EditImageModal from "@/components/modals/EditImageModal";
import ConfirmModal from "@/components/modals/ConfirmModal";
import draggable from 'zhyswan-vuedraggable';
import CommonDataService from "@/services/CommonDataService";
import CommonService from "@/services/CommonService";

export default {
	name: "ImageUploader",
	components: {
		EditImageModal,
		draggable
	},
	props: {
		maxImages: {
			type: Number,
			default: 1
		},
		allowedMime: {
			type: Array,
			default: () => {
				return [
					'image/jpeg',
					'image/jpg',
					'image/png',
					'.heic',
					'.heif',
				];
			}
		},
		inputId: {type: String},
		inputName: {type: String},
		imagesList: { type: Array },
		imagesAccept: { type: [Array, String] },
		currentSection: { type: String },
		inputValid: {type: String},
		inputInValid: {type: String},
		inputRequired: {type: Boolean},
		inputMultiple: {type: Boolean},
		inputObjectId: {type: String},
	},
	data() {
		return {
			id: (typeof this.inputId !== "undefined") ? this.inputId : '',
			name: (typeof this.inputName !== "undefined") ? this.inputName : '',
			is_drag: false,
			is_draggable: false,
			dragged: null,
			input: null,
			valid: (typeof this.inputValid !== "undefined") ? this.inputValid : false,
			invalid: (typeof this.inputInValid !== "undefined") ? this.inputInValid : false,
			uploadReadyState: true,
			uploadProgress: 0
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		showUpload() {
			const event = new MouseEvent('click', {
				'view': window,
				'bubbles': true,
				'cancelable': true
			});

			if (this.uploadReadyState) {
				this.input.dispatchEvent(event);
			}

		},
		validateInput(value) {

			if (typeof value == "string") {
				let ext = value.split('.').pop();
				if (typeof ext !== "undefined") {
					if (!CommonService.inArray(ext, this.accept))
						this.invalid = 'Данный тип файла не поддерживается.';
				} else if (!this.isEmpty(this.accept)) {
					this.invalid = 'Данный тип файла не поддерживается.';
				}
			}

			let input = this.$refs.addImageInput;
			if (this.invalid)
				input.setCustomValidity(this.invalid);
			else
				input.setCustomValidity("");

		},
		onFileSelect() {

			if (process.env.NODE_ENV == "development")
				console.debug('onFileSelect()', {
					files: this.input.files
				});

			this.prepareImage(this.input.files);
			this.input.value = null;
		},
		removeFile(index) {

			let params = {
				section: this.section,
				filename: this.images[index].filename,
				is_temp: this.images[index].is_temp,
				object_id: this.object_id,
			};

			let _this = this;
			api.delete('/common/file', {
				params: params
			}).then((response) => {

				if (process.env.NODE_ENV == "development")
					console.debug('removeFile()::axios', {
						response: response.data
					});

				if (response.status == 200/* && response.data.success*/)
					_this.images.splice(index, 1);

			}).catch(function (error) {

				CommonService.log('error', 'removeFile()::axios', error);

			});
		},
		deleteImage(image_id) {


			if(confirm('Вы действительно желаете удалить изображение?')) {

				let index = this.images.findIndex((image) => {

					if (image.id == image_id)
						return true;

					return false;
				})

				if (index !== -1)
					this.removeFile(index);

				CommonService.log('debug', 'deleteImage()', index);

			}
		},
		async imageConverter(file) {

			CommonService.log('debug', 'imageConverter()', file);

			const _this = this;
			const reader = new FileReader();
			reader.onload = function(e) {
				const blob = new Blob([new Uint8Array(e.target.result)], {type: file.type });
				const conv = heic2any({
					blob,
					//toType: "image/jpeg",
					quality: 0
				}).then((resource) => {

					if ((resource instanceof Blob) && (file instanceof File)) {
						let new_filename = file.name.replace(/\.[^/.]+$/, "_converted.jpeg");
						const new_file = new File([resource], new_filename, {
							type: resource.type,
						});

						CommonService.log('debug', 'imageConverter()::heic2any', new_file);

						return new_file;
					} else {

						CommonService.log('error', 'imageConverter()::heic2any', { file, resource });

					}

					return false;
				}).catch(err => {

					CommonService.log('error', 'prepareImages()::heic2any', err);

					return false;
				});

				conv.then((new_file) => {
					_this.uploadImage(new_file);
				})
			};
			reader.readAsArrayBuffer(file);
		},
		async uploadImage(file) {
			let _this = this;
			_this.uploadReadyState = false;

			let data = new FormData();
			data.append('section', _this.section);
			data.append(_this.section + '[]', file);

			api.post('/common/upload', data, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				timeout: 180000,
				onUploadProgress: progressEvent => {

					this.uploadProgress = Math.round((progressEvent.loaded/progressEvent.total)*100);

					if (process.env.NODE_ENV == "development")
						console.debug('uploadImage()::onUploadProgress', {
							progress: _this.uploadProgress
						});

					if (progressEvent.loaded === progressEvent.total) {

						_this.uploadReadyState = true;
						_this.uploadProgress = 0;

						if (process.env.NODE_ENV == "development")
							console.debug('uploadImage()::onUploadProgress', {
								progress: 'COMPLETED!'
							});
					}
				}
			}).then((response) => {

				if (process.env.NODE_ENV == "development")
					console.debug('uploadImage()::axios', {
						response: response.data
					});

				if (response.status == 200 && response.data.success) {
					_this.uploadReadyState = true;
					_this.uploadProgress = 0;
					if (response.data.files.length) {

						let order = 1;
						for (let file of response.data.files) {
							if (file.path) {
								_this.images.push({
									filename: file.filename,
									fullsize: file.path,
									thumbnail: file.path,
									is_main: false,
									is_temp: file.is_temp,
									is_editable: file.is_editable,
									order: order,
									editor: [],
								});
								order++;
							}
						}

						if (response.data.section == 'photo' && _this.images.length == 1) {
							if (_this.images[0].is_main != 1) {
								_this.images[0].is_main = true;
							}
						}
					}

					_this.$emit('onImageUploaded', response.data.section, this.images);
				}

			}).catch(function (error) {

				_this.uploadReadyState = true;
				_this.uploadProgress = 0;

				CommonService.log('error', 'prepareImage()::axios', error);

			});
		},
		prepareImage(files) {

			let data = new FormData();
			if (this.section) {
				for (let file of files) {

					let max_size = parseInt(this.$store.getters.options.upload_max);
					if (parseInt(this.$store.getters.options.post_max) < max_size)
						max_size = parseInt(this.$store.getters.options.post_max);

					if (max_size < file.size) {
						this.$emit('inputInvalid', 'Файл '+file.name+' превышает допустимый размер для загрузки '+CommonService.formatBytes(max_size)+'.');
						continue;
					}

					if (this.inArray(file.type, this.$props.allowedMime) && !this.inArray(file.type, ['image/heic', 'image/heif'])) {
						this.uploadImage(file);
					} else if (this.inArray(file.type, ['image/heic', 'image/heif']) || file.name.indexOf("HEIC")) {
						this.imageConverter(file);
					}
				}
			}
		},
		declOfNum(number, titles, append) {
			return CommonService.declOfNum(number, titles, append);
		},
		enableDrag(event) {
			this.is_draggable = true;
			event.target.closest('.media-actions').style.pointerEvents = 'none';
			this.dragged = event.target.closest('.media').closest('.col');
		},
		disableDrag() {
			this.is_draggable = false;
			if (this.dragged !== null) {
				this.dragged.querySelector('.media-actions').style = '';
				this.dragged.style = '';
				this.dragged = null;
			}
		},
		dragStart() {
			this.is_drag = true;
		},
		recalcOrder() {

			let order = 1;
			this.images.forEach(image => {

				if (order == 1)
					image.is_main = true;
				else
					image.is_main = false;

				image.order = order;
				order++;
			});
			this.images = CommonService.sortArray(this.images, 'order');
		},
		dragEnd(data) {
			this.is_drag = false;
			this.disableDrag();
			this.recalcOrder();
		},
		applyFlip(data) {

			CommonService.log('debug', 'applyFlip()', data);

			this.images.forEach(image => {
				if (image.filename == data.file_name) {
					image.editor.flip = data.data;
				}
			});
		},
		applyRotate(data) {

			CommonService.log('debug', 'applyRotate()', data);

			this.images.forEach(image => {
				if (image.filename == data.file_name) {
					image.editor.rotate = data.data;
				}
			});
		},
		applyCrop(data) {

			CommonService.log('debug', 'applyCrop()', data);

			this.images.forEach(image => {
				if (image.filename == data.file_name) {
					image.editor.crop = data.data;
				}
			});
		},
		imageEdited(data) {

			CommonService.log('debug', 'imageEdited():data', data);

			this.images.forEach(image => {

				if (data.is_main)
					image.is_main = false;

				if (image.filename == data.file_name) {
					image.thumbnail = data.image_src;
					image.editor = data.data;

					if (data.is_main)
						image.is_main = true;

				}
			});

			CommonService.log('debug', 'imageEdited()', this.images);

		},
	},
	mounted() {

		this.input = this.$refs.addImageInput;
		if (this.input) {
			this.input.addEventListener('change', () => this.onFileSelect());
			this.input.style.display = 'none';

			if (this.max > 1)
				this.input.setAttribute('multiple', 'multiple');
			else
				this.input.removeAttribute('multiple');

			/*if (this.$props.imagesList)
				this.images = this.$props.imagesList;
			else
				this.$emit('setValue', []);*/

			this.$nextTick(() => {

				if (typeof this.$props.allowedMime !== "undefined")
					this.input.accept = this.$props.allowedMime;

			});
		}
	},
	computed: {
		object_id: {
			get() {
				return (typeof this.$props.inputObjectId !== "undefined") ? this.$props.inputObjectId : null;
			},
			set(value) {
				value = value.trim();
			}
		},
		max() {
			return this.$props.maxImages
		},
		section() {
			return this.$props.currentSection ?? null
		},
		required() {
			return this.$props.inputRequired;
		},
		multiple() {
			return this.$props.inputMultiple;
		},
		accept() {

			let accept = this.$root.config.upload_accept.images;
			if (typeof this.$props.imagesAccept !== "undefined")
				accept = this.$props.imagesAccept;

			if (typeof accept === 'string')
				accept = accept.split(',');

			return accept;
		},
		images() {
			console.log(this.$props.imagesList);
			return (typeof this.$props.imagesList !== "undefined") ? this.$props.imagesList : [];
		},
	},
	watch: {
		images(value, oldValue) {
			this.$emit('setValue', value);
		}
	}
};
</script>